.container {
  margin-top: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.count_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.card_container:hover {
  background-color: #dbdfe9;
}

.count_text {
  margin-top: 7px;
  font-size: 15px;
  line-height: 23px;
  font-weight: 800;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: -10px;
}

.refreshContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 3px;
  position: relative;
  top: -50px;
  left: 90%;
  width: min-content;
}
