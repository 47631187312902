.tab {
  display: inline-block;
  padding: 10px 7px;
  cursor: pointer;
  text-decoration: none; /* Remove default underline */
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  color: #99a1b7;
}

.tab.active {
  border-bottom: 3px solid #3e97ff; /* Blue line for active tab */
  text-decoration: none; /* Remove underline for active tab */
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  color: #3e97ff;
}

.tab-group {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.tab-group .tab {
  margin-right: 20px;
}

.integrationLoader {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: bold;
  flex-wrap: wrap;
  margin-top: 100px;
  
}
