.hide {
  display: none;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: -5px;
}

.img_name {
  width: 150px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.img_name p {
  font-size: medium;
  font-weight: bold;
  color: #99a1b7;
}
.img_name p:hover {
  font-size: medium;
  font-weight: bold;
  color: #0095e8;
}
.discription {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -20px;
  margin-bottom: 10px;
}
.discription p {
  font-size: 15px;
  font-weight: 600;
  color: #99a1b7;
}

.platform_btn_container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  column-gap: 0px;
}
