.container_div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 120px;
  width: auto;
}
.platformName_div {
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  justify-content: center;
  position: absolute;
  top: 0;
}
.platform_info_name {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.platformName {
  font-size: 15px;
  line-height: 20px;
  font-weight: 700;
  margin-bottom: -10px;
  text-align: center;
}
.status {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  margin-top: 15px;
}
.status_title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.count_details_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
  width: 100%;
  cursor: pointer;
}
.count_details_div p {
  font-size: 15px;
  font-weight: 800;
}
.count_details_textContainer_01 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  top: 6px;
}
.count_details_textContainer_02 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  top: -7px;
}
.count_details_textContainer_03 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  top: -20px;
}
.count_details_01 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

/* For Text Hover Expend------------- */
.textCollapse {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.textCollapse:hover {
  -webkit-line-clamp: unset;
}
/* For Text Hover Expend------------- */


.infoCard:hover {
  filter: drop-shadow(1px 2px 3px gray);
}

.appSearchBar {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  position: relative;
  margin-top: -20px;
  margin-bottom: 7px;
}
