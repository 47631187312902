.page_heading {
  position: relative;
  top: -20px;
}

.button_container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.border {
  border: 1px solid red;
}
.card_subTitle {
  margin-bottom: 10px;
  color: #9fa7bc;
  font-size: 16px;
}
.card_subTitle:hover {
  color: #3e97ff;
}
.col_cls {
  margin-top: 7px;
}
