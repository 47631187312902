.comb_btn {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin: 7px 0px;
}
.comb_cancel {
  position: relative;
  top: -35px;
  left: 100%;
}

/* @media screen and (min-width: 320px) and (max-width: 425px) {
  .comb_cancel {
    top: -50px;
    left: 100%;
  }
} */
/* @media screen and (min-width: 426px) and (max-width: 767px) {
  .comb_cancel {
    top: -50px;
    left: 97%;
  }
} */
@media screen and (min-width: 768px) and (max-width: 991px) {
  .comb_cancel {
    top: -32px;
    left: 100%;
  }
}
@media screen and (min-width: 992px) and (max-width: 1024px) {
  .comb_cancel {
    top: -35px;
    left: 100%;
  }
}
.default_text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin-top: 2px;
}

.mapInternally_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  column-gap: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  position: relative;
  left: 3px;
  top: 30px;
  width: fit-content;
}
.mapInternally_container:hover {
  color: #2196f3;
}

@media screen and (min-width: 320px) and (max-width: 334px) {
  .mapInternally_container {
    top: 5px;
  }
}

.subcombination_individualField {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
}

.submit_btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.show {
  display: block;
}
.hidden {
  display: none;
}