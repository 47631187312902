.slideshow-container {
    position: relative;
  }
  
  .middle-bottom {
    position: relative;
    display: flex;
    flex-direction: row;
    column-gap: 30px;
    bottom: -3px;
    left: 50%;
    transform: translateX(-25%);
    cursor: pointer;
  }
  
  .text-center {
    text-align: center;
  }
  