.parentDiv {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* Semi-transparent background */
  background-color: #999999;
  /* Bluring */
  backdrop-filter: blur(1px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.full_screen_modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%; /* Adjust the maximum width of the modal */
  width: 80%;
  max-height: 100%; /* Adjust the maximum height of the modal */
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto; /* Enable scrolling if content exceeds modal height */
  border-radius: 10px;
}

.modal_content_full {
  /* background-color: rgba(255, 255, 255, 0.9); */
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(10, 10, 10, 0.8);
  border-radius: 5px;
  padding: 20px;
  width: 100%;
  height: 100%; /* Set a maximum height for the content inside the modal */
  /* overflow-y: auto;  */
  /* Enable vertical scrolling within the content */
  padding: 40px;
  /* overflow-y: scroll; */
  /* overflow-x: scroll; */
}

.modal_content_small {
  /* background-color: rgba(255, 255, 255, 0.9); */
  background-color: white;
  box-shadow: 0 0 10px rgba(10, 10, 10, 0.8);
  border-radius: 5px;
  padding: 20px;
  width: 100%;
  height: 100%; /* Set a maximum height for the content inside the modal */
  /* overflow-y: auto;  */
  /* Enable vertical scrolling within the content */
  padding: 40px;
  /* overflow-y: scroll; */
  /* overflow-x: scroll; */
}

.submitButton_div {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.visible {
  display: block;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.show {
  display: block;
}
.hidden {
  display: none;
}

.child_div {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: 20px;
  width: '100%';
}
.combination_parent_div {
  height: 150px;
  margin-top: 30px;
  width: 100%;
  overflow-y: scroll;
  overflow-x: scroll;
}
.switchSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 7px;
}

.line_container {
  width: 100%;
  position: relative;
}

.line {
  position: absolute;
  bottom: 0;
  left: 20%;
  right: 20%;
  height: 3px; /* Adjust line height as needed */
  background-color: #bdbdbd; /* Line color */
}

/* Custom Switch Styling */

/* ToggleSwitch.module.css */
.switch {
  position: relative;
  display: inline-block;
  width: 3.6rem;
  height: 22px; /* Reduced height */
  border-radius: 11px; /* Make it capsule-shaped */
  border: 3px solid #2196f3;
  margin-bottom: 2.5px;
  margin-top: 22px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #2196f3;
  border-radius: 10px; /* Match the switch's border-radius */
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 15px; /* Reduced height */
  width: 15px; /* Reduced width */
  left: -1px;
  bottom: 1px;
  background-color: white;
  border-radius: 50%; /* Make it circular */
  transition: 0.4s;
}

@media (max-width: 320px) {
  .slider:before {
    left: -3.5px;
  }
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

/* Rounded sliders */
.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.show_subCombination_container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  column-gap: 10px;
  margin-top: 2px;
}

.individual_child_container {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
}

.mapInternally_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  position: relative;
  left: 8px;
  top: 50px;
  width: fit-content;
}
.mapInternally_container:hover {
  color: #2196f3;
}

.loader {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
