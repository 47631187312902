.reportParameter_container {
  padding: 20px;
}

.heading {
  font-size: 20px;
  line-height: 23px;
  font-weight: 600;
  color: #3e97ff;
  margin-top: 10px;
  margin-bottom: 20px;
}

.button_container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  column-gap: 10px;
}
