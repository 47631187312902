.heading {
  font-size: 18px;
  line-height: 21px;
  font-weight: 600;
  color: #99a1b7;
  margin-top: 20px;
}
.heading:hover {
  color: #3e97ff;
}

.switchHeadings {
  font-size: 13.5px;
  line-height: 20px;
  font-weight: 600;
  color: hsl(224, 17%, 66%);
  margin-top: 20px;
  margin-left: 7px;
  display: flex;
  position: relative;
  bottom: 7px;
}
.switchHeadings:hover {
  color: #3e97ff;
}

.customSwitchcomponent_main {
  margin-top: 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
}

.upowrdLoader {
  position: absolute;
  top: 60%;
  left: 50%;
  z-index: 10;
}
.enableWorkflow_div {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  margin-right: 15px;
}
.enableWorkflow_form {
  display: flex;
  column-gap: 10px;
}

/* Logo Header*/

.platform_status {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  padding: 12px;
  margin-bottom: 15px;
  background-color: rgba(255, 166, 0, 0.495);
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 7px;
}

.img_name {
  width: 150px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: #0095e8; */
}
.img_name p {
  font-size: 13px;
  font-weight: bold;
  color: #99a1b7;
  margin-top: 5px;
}
.img_name p:hover {
  font-size: 13px;
  font-weight: bold;
  color: #0095e8;
  margin-top: 5px;
}
