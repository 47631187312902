#table_data {
  border-bottom: 1px solid #ddd;
  border-collapse: collapse;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  width: 100%;
}

#table_data td,
#table_data th {
  border: 1px solid #ddd;
  padding: 8px;
}

#table_data tr:nth-child(even) {
  background-color: #756d6d36;
}
#table_data tr:hover {
  background-color: #61a883;
}

#table_data th {
  background-color: #04aa6d;
  text-align: center;
  color: white;
  padding-bottom: 12px;
  padding-top: 12px;
  font-size: 15px;
}

/* Modal Css -------------------------------*/
.parentDiv {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  /* Semi-transparent background */
  background-color: #999999;
  /* Bluring */
  backdrop-filter: blur(1px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.full_screen_modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%; /* Adjust the maximum width of the modal */
  width: 80%;
  max-height: 100%; /* Adjust the maximum height of the modal */
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto; /* Enable scrolling if content exceeds modal height */
  border-radius: 10px;
}

.modal_content_full {
  /* background-color: rgba(255, 255, 255, 0.9); */
  background-color: #ffffff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(10, 10, 10, 0.8);
  width: 100%;
  height: 100%; /* Set a maximum height for the content inside the modal */
  /* overflow-y: auto;  */
  /* Enable vertical scrolling within the content */
  padding: 40px;
  /* overflow-y: scroll; */
  /* overflow-x: hidden; */
}

.modal_content_small {
  /* background-color: rgba(255, 255, 255, 0.9); */
  background-color: white;
  box-shadow: 0 0 10px rgba(10, 10, 10, 0.8);
  border-radius: 5px;
  padding: 20px;
  width: 100%;
  height: 100%; /* Set a maximum height for the content inside the modal */
  /* overflow-y: auto;  */
  /* Enable vertical scrolling within the content */
  padding: 40px;
  /* overflow-y: scroll; */
  /* overflow-x: scroll; */
}

/* Previously Saved Mapped Section: */
.previouslyMapped_row {
  background-color: #dbdfe9;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}
.previouslyMapped_column {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
}
.previouslyMapped_column:hover {
  color: #6997ff;
}
.previouslyMapped_text {
  font-size: 15px;
  font-weight: 500;
  /* text-overflow: ellipsis; */
  /* overflow: hidden; */
  /* white-space: nowrap; */
}
.previouslyMapped_arrow {
  font-size: 20px;
  font-weight: bolder;
  display: flex;
}

#combination_button_div {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
#combination_container {
  height: auto;
  max-height: 210px;
  overflow-y: scroll;
  overflow-x: scroll;
}
#combination_parent_div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 20px;
  margin-top: 10px;
}
#combination_sourceField_div {
  width: 100%;
  margin-bottom: 2px;
}
#combination_destinationField_div {
  width: 100%;
  margin-bottom: 2px;
}

#combination_sourceField {
  border-radius: 5px;
  height: 40px;
  width: 100%;
  font-size: 13px;
  line-height: 20px;
  font-weight: 600;
  border: 1px solid #99a1b7;
  outline: none;
  padding: 8px;
  color: black;
  background-color: white;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='20' viewBox='0 0 24 24' fill='none' stroke='%23999' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-position: right 8px center;
  background-repeat: no-repeat;
}
#combination_sourceField_str {
  border-radius: 5px;
  height: 40px;
  width: 100%;
  font-size: 13px;
  line-height: 20px;
  font-weight: 600;
  border: 1px solid #99a1b7;
  outline: none;
  padding: 8px;
  color: black;
  background-color: white;
  appearance: none;
  background-position: right 8px center;
  background-repeat: no-repeat;
}

#combination_destinationField {
  border-radius: 5px;
  height: 40px;
  width: 100%;
  font-size: 13px;
  line-height: 20px;
  font-weight: 600;
  border: 1px solid #99a1b7;
  outline: none;
  padding: 8px;
  color: black;
  background-color: white;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='20' viewBox='0 0 24 24' fill='none' stroke='%23999' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-position: right 8px center;
  background-repeat: no-repeat;
}

#combination_destinationField_str {
  border-radius: 5px;
  height: 40px;
  width: 100%;
  font-size: 13px;
  line-height: 20px;
  font-weight: 600;
  border: 1px solid #99a1b7;
  outline: none;
  padding: 8px;
  color: black;
  background-color: white;
  appearance: none;
  background-position: right 8px center;
  background-repeat: no-repeat;
}


.updateButton_div{
  display: flex;
  justify-content: center;
}