.button {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.show {
  display: flex;
}
.hide {
  display: none;
}
.class_loader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.line {
  border-top: 5px solid black;
}

.show_line {
  position: relative;
  margin-bottom: 20px;
}

.show_line::before {
  top: 50%;
  left: 30%;
  right: 30%;
  margin-top: 15px;
  content: "";
  position: absolute;
  border-top: 5px solid #bdbdbd;
  transition: border-color 0.3s ease;
}

.shortCodeDisplayContainer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.shortCodes{
  display: flex;
  flex-direction: row;
  column-gap: 2px;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}


