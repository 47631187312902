.page_heading {
  font-size: 25px;
  line-height: 25px;
  font-weight: 600;
}

.page_subHeading {
  display: inline;
  font-weight: bolder;
}
.editProfile_cardHeader {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  font-size: 16px;
  font-weight: 700;
}
.editProfile_card_div {
  display: flex;
  flex-direction: row;
}
.editProfile_card_detailName {
  font-size: 15px;
  line-height: 18px;
  font-weight: 600;
}
.editProfile_card_detailValues {
  font-size: 15px;
  line-height: 18px;
  font-weight: 600;
}

.editProfile_card_detailValues p:hover {
  color: #5d97ff;
}

.changePassword_error {
    color: red;
}
