/* Header Section Css */
.breadcrumbs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: -10px;
}
.primary_Headercomponent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  column-gap: 100px;
}
.breadcrumbs_orderDetails {
  display: flex;
  flex-direction: column;
}
.breadcrumbs_orderDetails_span {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
.papre_one {
  background-color: #c0c8de;
  padding: 1px 10px 1px 5px;
  border-radius: 10px;
  font-weight: bolder;
}
.papre_two {
  background-color: rgb(218, 238, 108);
  padding: 1px 7px 1px 7px;
  border-radius: 10px;
  font-weight: bolder;
  color: white;
}

/* Body Section Css */
.card_header {
  font-weight: bolder;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: -30px;
}
.card_subHeading_product {
  margin-bottom: 2px;
  color: #9ca4b9;
  text-align: start;
}
.card_subHeading {
  margin-bottom: 2px;
  color: #9ca4b9;
  text-align: center;
}
.card_text_productDetail {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
}
.card_text {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
}
.card_text_productDetail:hover {
  color: #74b4ff;
}
.card_text:hover {
  color: #74b4ff;
}

.track_order_button {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
}
.shipment_product_details {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 70px;
}
.address_section {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 7px;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.no_record_message {
  display: flex;
  justify-content: center;
  align-items: center;
}
