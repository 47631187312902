.subHeader_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;
  column-gap: 10px;
  flex-wrap: wrap;
  margin-right: -20px;
}
.applyAll_checkbox {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  font-weight: 600;
  align-items: baseline;
}
