.heading {
  font-size: 18px;
  line-height: 21px;
  font-weight: 600;
  color: #99a1b7;
  margin-top: 20px;
}
.heading:hover {
  color: #3e97ff;
}

.switchHeadings {
  font-size: 13.5px;
  line-height: 20px;
  font-weight: 600;
  color: hsl(224, 17%, 66%);
  margin-top: 20px;
  margin-left: 7px;
  display: flex;
  position: relative;
  bottom: 7px;
}
.switchHeadings:hover {
  color: #3e97ff;
}

.customSwitchcomponent_main {
  margin-top: 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
}

.upowrdLoader {
  position: absolute;
  top: 60%;
  left: 50%;
  z-index: 10;
}

.platform_status {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-weight: bold;
  position: relative;
  right: 40px;
  top: 17px;
}
.enableWorkflow_div {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  margin-right: 15px;
}
.enableWorkflow_form {
  display: flex;
  column-gap: 10px;
}

.addflow_button {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: -50px;
}

.heading {
  font-size: 20px;
  line-height: 23px;
  font-weight: 600;
  color: #3e97ff;
  margin-top: 10px;
  margin-bottom: 20px;
}

.moreAvailableField_container {
  padding: 25px;
}

.initial_submitButton {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.show {
  display: block;
  width: 100%;
}
.hide {
  display: none;
}

.button {
  margin: 30px 0px 10px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.visible {
  /* border: 2px solid #f1f1f2; */
  border-radius: 10px;
}

.complete_mapping {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.modal_header {
  color: #4b9eff;
  font-size: 20px;
  font-weight: bolder;
}

.shortCodeDisplayContainer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.shortCodes {
  display: flex;
  flex-direction: row;
  column-gap: 2px;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}

.configrationCorner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 30px;
}
