/* Header Css */
.breadcrumbs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: -10px;
}
.primary_Headercomponent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  column-gap: 100px;
}
.breadcrumbs_orderDetails {
  display: flex;
  flex-direction: column;
}
.breadcrumbs_orderDetails_span {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
/* Body Css */
.card_header {
  font-weight: bolder;
  font-size: 15px;
  padding: 10px 0;
  margin-bottom: 10px;
  border-bottom: 1px solid #9ca4b955;
}
.productInfoGropuContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  /* column-gap: 20px; */
  flex-wrap: wrap;
}
.productInfoContainer {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.card_subHeading_product {
  margin-bottom: 2px;
  color: #9ca4b9;
  text-align: start;
}

.card_text_productDetail {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  overflow-x: hidden;
}

.card_text_productDetail:hover {
  color: #74b4ff;
}

.varientContainer {
  border-radius: 10px;
  padding: 15px 0px 0px 10px;
  margin-bottom: 7px;
}

.card_text:hover {
  color: #74b4ff;
}

.card_subHeading {
  margin-bottom: 2px;
  color: #9ca4b9;
  text-align: center;
}

.card_text {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
}
.card_text_productDetail:hover {
  color: #74b4ff;
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}


